@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');

body {
  margin: 0;
  font-family: Lexend, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 50px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  left: 20px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.burn-tooltip {
  left: 20px;
  bottom:20px;
}

#content {
  margin-bottom: 30px;
  padding-bottom: 40px;
  height: auto;
}

#nav {
  display: flex;
  width: 700px;
  margin: 0 auto;
}

#nav div {
  width: 33%;
  justify-content: space-between;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.invisible {
  display: none !important;
}

.activeNav {
  border-bottom: 3px solid #1E8079;
}

#top-text {
  margin-bottom: 60px;
}

h1 {
  font-family: Lexend;
  font-size: 58px;
  font-weight: 500;
  line-height: 74px;
  letter-spacing: -0.03em;
  text-align: center;
  margin-bottom: -20px;
  margin-top: 20px;
}

h2 {
  font-family: Lexend;
  font-size: 19px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: -15px;
}

h3 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: -25px;
}

h4 {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}

label {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b5b5b5;
  opacity: 1; /* Firefox */
}

input, textarea {
  font-family: Lexend;
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 10px;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  height: 54px;
  padding-left: 10px;
}

.bitcoin-pubkey {
  min-width: 100%;
  max-width: 100%;
}

#bond-spec {
  width: 100%;
  height: 5em;
}

.tx-hex {
  width: 100%;
  height: 5em;
}

.form-row {
  display: flex;
  width: 100%;
}

#left {
  width: 45%;
  display: flex;
  justify-content: space-between;
}

#right {
  width: 50%;
}
.form-group {
  padding: 5px;
  width: 45%;
}

.form-group input {
  width: 100%;
}

#amt-hint {
  color: #1E8079 !important;
  padding-top: 60px;
  padding-left: 20px;
}

#burn-explained {
  color: #146C52;
}
.content {
  width: 757px;
  height: 320px;
  background: linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 100%);
  margin: 0 auto;
  padding-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
}

#instructions {
  width: 96%;
  padding: 8px 22px 8px 22px;
  border-radius: 4px;
  gap: 4px;
  margin: 0 auto;
  display: flex;
  align-content: flex-start;
  justify-content: center;
  align-items: center;

  background: #CAF5FB;
}
#instructions img {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}

#instructions p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  display: block;
  width: 100%;
}

#instructions img {
  display: block;
  margin: 0 auto;
}

.generate-btn {
  background: #2CCCBF;
  border-width: 0px;
  color: #FFFFFF;

  width: 254px;
  height: 54px;
  padding: 15px 13px 15px 13px;
  border-radius: 4px;
  gap: 8px;
  opacity: 0.5px;

  font-family: Lexend;
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  margin-top: 10px;
}

#generate-claim-tx-btn { /* specific to claim page */
  margin-bottom: 30px;
}
#burn-tx-holder, #reclaim-tx-holder {
  margin-top: -50px;
  margin-bottom: 30px !important;
  height: auto;
}
#send-claim-tx-btn, #send-reclaim-tx-btn {
  margin-bottom: 20px !important;
}

#addr {
  text-align: center;
  color: green;
}

#spec {
  text-align: center;
  color: green;
}

#newSpec {
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  /*background: #CAF5FB;*/

  width: 100%;
  padding: 8px 22px 8px 22px;
  border-radius: 4px;
  gap: 4px;
  margin: 0 auto;
  overflow-wrap: anywhere;

  display: none;
}

#send-tx-btn {
  background: #3495A3;
  border-width: 0px;
  color: #FFFFFF;

  width: 254px;
  height: 54px;
  padding: 15px 13px 15px 13px;
  border-radius: 4px;
  gap: 8px;
  opacity: 0.5px;

  font-family: Lexend;
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  margin: 0 auto;
  margin-top: -15px;
  display: block;
}

#thin-line {
  width: 100%;
  height: 2px;
  background-color: #3495A3;
  margin: 30px 0 0px 0;
}
.visible {
  display: inherit !important;
}

.invisible {
  display: none;
}

#spec-info {
  margin-top: 20px;
  font-size: 16px;
}

#burn-tx-holder, #reclaim-tx-holder {
  width: 90%;
  height: 240px;
  overflow-wrap: anywhere;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#burn-tx, #reclaim-tx {
  height: 100px;
  overflow-y: auto;
  padding: 5px;
  border: 1px solid blue;
}

#burn-tx-holder button, #reclaim-tx-holder button {
  margin: 0 auto;
  display: block;
}

.error-input {
  border: 1.5px solid red;
}

footer {
  /*text-align: center;
  padding-top: 15px;
  padding-bottom: 5px;*/
}

#broadcast-status {
  text-align: center;
  margin-bottom: 20px !important;
}